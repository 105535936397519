@font-face {
    font-family: 'Aeonic';
    src: local('Aeonic'), url("./Aeonik/Aeonik-Air.ttf") format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonic';
    src: local('Aeonic'), url("./Aeonik/Aeonik-AirItalic.ttf") format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonic';
    src: local('Aeonic'), url("./Aeonik/Aeonik-Thin.ttf") format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonic';
    src: local('Aeonic'), url("./Aeonik/Aeonik-ThinItalic.ttf") format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonic';
    src: local('Aeonic'), url("./Aeonik/Aeonik-Light.ttf") format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonic';
    src: local('Aeonic'), url("./Aeonik/Aeonik-LightItalic.ttf") format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonic';
    src: local('Aeonic'), url("./Aeonik/Aeonik-Regular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonic';
    src: local('Aeonic'), url("./Aeonik/Aeonik-RegularItalic.ttf") format('truetype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonic';
    src: local('Aeonic'), url("./Aeonik/Aeonik-Medium.ttf") format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonic';
    src: local('Aeonic'), url("./Aeonik/Aeonik-MediumItalic.ttf") format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonic';
    src: local('Aeonic'), url("./Aeonik/Aeonik-Bold.ttf") format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonic';
    src: local('Aeonic'), url("./Aeonik/Aeonik-BoldItalic.ttf") format('truetype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonic';
    src: local('Aeonic'), url("./Aeonik/Aeonik-Black.ttf") format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonic';
    src: local('Aeonic'), url("./Aeonik/Aeonik-BlackItalic.ttf") format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2024 MyFonts Inc. */


  
@font-face {
  font-family: "FranklinGothicBook";
  src: url('./FranklinGothicURW/FranklinGothicBook/font.woff2') format('woff2'), url('./FranklinGothicURW/FranklinGothicBook/font.woff') format('woff');
}
@font-face {
  font-family: "FranklinGothicHeavy";
  src: url('./FranklinGothicURW/FranklinGothicHeavy/font.woff2') format('woff2'), url('./FranklinGothicURW/FranklinGothicHeavy/font.woff') format('woff');
}
@font-face {
  font-family: "FranklinGothicDemi";
  src: url('./FranklinGothicURW/FranklinGothicDemi/font.woff2') format('woff2'), url('./FranklinGothicURW/FranklinGothicDemi/font.woff') format('woff');
}
@font-face {
  font-family: "FranklinGothicMedium";
  src: url('./FranklinGothicURW/FranklinGothicMedium/font.woff2') format('woff2'), url('./FranklinGothicURW/FranklinGothicMedium/font.woff') format('woff');
}
@font-face {
  font-family: "FranklinGothicDemiCompressed";
  src: url('./FranklinGothicURW/FranklinGothicDemiCompressed/font.woff2') format('woff2'), url('./FranklinGothicURW/FranklinGothicDemiCompressed/font.woff') format('woff');
}