@import "./vars.module";

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35%;
  background-color: #FFFFFF;
  border: 1px solid var(--primary-main);
  padding: 2rem;
  @media screen and (max-width: 769px) {
    width: 70%;
  }
}

.dialogContent {
  background-color: #1C1D23;
  border: 1px solid var(--primary-main);
  padding: 2rem;
  .dialogTitle {
    text-align: center;
    padding: 5px 24px;
    h2 {
      color: #68FFDC;
      font-size: 25px;
    }
  }
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;

  button {
    border: 1px solid #999999;
    border-radius: 20px;
    height: 35px;
    width: 40%;

    &.saveButton {
      color: #FFFFFF;
      background-color: var(--primary-main);
    }

    &.saveButton:hover {
      color: #FFFFFF;
      background-color: var(--secondary-main);
    }

    &.cancelButton {
      color: #FFFFFF;
      background-color: #5b5b5b;
    }

    &.cancelButton:hover {
      color: #FFFFFF;
      background-color: red;
    }
  }
}

.loader {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

